<template>
	<el-dialog destroy-on-close :close-on-click-modal="false" @close="close" ref="dialog" :title=" type == 0 ? '新增' : '编辑' " :visible.sync="show" width="400px">
		<el-form ref="form" label-width="90px" label-position="left" :model="form">
			<el-form-item label="模型名称" prop="modelName">
				<el-input v-model="form.modelName" placeholder="请输入设备编号" :disabled=" type == 0 ? false : true "></el-input>
			</el-form-item>
			<el-form-item prop="typeCode" label="请选择设备类型">
				<div slot="label">设备类型</div>
				<!-- <span style="color: red;"> *</span> -->
				<el-select v-model="form.typeCode" placeholder="请选择设备类型">
					<el-option label="" value="" ></el-option>
				    <el-option
						v-for="( item , i ) in typeList"
						:key="i"
						v-if="i!=6"
						:label="item.typeName"
						:value="item.typeCode"
					>
				    </el-option>
				</el-select>
			</el-form-item>
			<el-divider content-position="left">
				<span style="color: #037fff;font-size: 16px;"><i class="el-icon-picture mg_r"></i>设备图片</span>
			</el-divider>
			<el-upload
				class="avatar-uploader"
				ref="upload"
				action="https://jsonplaceholder.typicode.com/posts/"
				list-type="picture-card"
				:on-exceed="on_exceed"
				:auto-upload="false"
				:limit="1">
			  <i class="el-icon-plus"></i>
			</el-upload>
			<!-- <div class="show_img_list">
				<div class="img_item" v-if=" form.modelImg != '' " >
					<div class="close" @click="del_img( i )" >
						<i class="el-icon-close"></i>
					</div>
					<img :src="form.modelImg">
				</div>
			</div> -->
			<el-form-item class="pd">
				<el-button type="primary" @click="onSubmit" style="margin-right: 20px;">{{ type == 0 ? '新增' : '编辑' }}</el-button>
				<el-button @click=" show = false ">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default{
		data(){
			return {
				type : 0,
				show : false,
				form:{
					modelName:'',
					typeCode:'',
					modelImg:''
				},
				typeList:[]
			}
		},
		mounted(){
			this.init_getTypeList();
		},
		methods: {
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			on_exceed( files, fileList ){
				this.$message({
					type: 'error',
					message: "图片数量已经达到上传限制,请先删除"
				});
			},
			async onSubmit(){
				if( !this.form.modelName ){
					this.$message({
						type: 'error',
						message: "请填写设备名称"
					});
					return;
				}
				if(!this.form.typeCode){
					this.$message({
						type: 'error',
						message: "请填写设备类型"
					});
					return;
				}
				if( this.$refs.upload.uploadFiles.length > 0 ) {
					let upload_upload = await this.picture_upload();
					this.form.modelImg = upload_upload.data[0]
				}else{
					if( this.type == 0 ){
						this.$message({
							type: 'error',
							message: "请选择设备图片"
						});
						return;
					}
				}
				this.$api[this.type == 0 ? 'deviceModelAdd' : 'deviceModelUpdate'](this.form).then(res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.message
						});
						this.$emit( 'success' , 'ok' );
						this.$refs.form.resetFields();
						this.show = false;
					}
				});
			},
			async picture_upload(){
				let formData = new FormData();
				this.$refs.upload.uploadFiles.forEach((item, i) => {
					formData.append('multipartFiles', item.raw);
				});
				let upload_upload = await this.$api.monitoringPoint_uploadPicture(formData);
				if (upload_upload.code != 200) {
					this.$message({
						type: 'error',
						message: upload_upload.message
					});
				}
				return upload_upload;
			},
			close( done ){
			  this.reset_form_data();
			  this.$refs.upload.clearFiles();
			  // return done( true );
			},
			open( data ){
				if( this.type == 1 ){
					this.form = Object.assign( {} , data );
				}
				this.show = true;
			},
			reset_form_data(){
				this.form = {
					modelName:'',
					typeCode:'',
					modelImg:''
				}
			},
		}
		
	}
	
	
</script>

<style>
</style>