<template>
	<div>
		<el-form :inline="true" :model="formSearch">
			<el-form-item label="设备类型">
				<el-select v-model="formSearch.typeCode" placeholder="请选择设备类型">
					<el-option label="" value=""></el-option>
					<el-option v-for=" ( item , i ) in typeList " :key="i" v-if="i!=6" :label="item.typeName" :value="item.typeCode"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button @click="device_list()" type="primary">查询</el-button>
			</el-form-item>
		</el-form>
		<el-row class="mg_b text_r">
			<el-button type="primary" icon="el-icon-plus" @click="addImage( 0 )" circle></el-button>
		</el-row>
		<el-table :data="list" border style="width: 100%" v-loading="loading">
			<el-table-column prop="modelName" label="设备名称" ></el-table-column>
			<el-table-column prop="modelImg" label="设备图片" >
				<template slot-scope="scope">
					<el-image style="width: 100px; height: 100px" :src="scope.row.modelImg" fit='contain' :preview-src-list="[scope.row.modelImg]"></el-image>
				 </template>
			</el-table-column>
			<el-table-column prop="createTime" label="创建时间" ></el-table-column>
			<el-table-column prop="updateTime" label="修改时间" ></el-table-column>
			<el-table-column label="操作" fixed="right" width="140">
			    <template slot-scope="scope">
					<el-button size="mini" icon="el-icon-edit" type="primary" @click="addImage( 1 , scope.row )" plain style="margin-right: 10px;">编辑</el-button>
					<el-popconfirm title="是否要删除？" icon-color="red" @confirm="delTypeImage( scope.row.id )" >	
						<el-button slot="reference" size="mini" type="danger" icon="el-icon-delete" plain>删除</el-button>
					</el-popconfirm>
			    </template>
			</el-table-column>
		</el-table>
		<div class="page mg_t text_c">
			<el-pagination 
				@current-change="changePage"
				@size-change="sizeChange"
				:page-sizes="[ 10 , 20 , 30 , 40]" 
				:total="total" 
				:currentPage="formSearch.startPage" 
				:pageSize="formSearch.pageSize" 
				background
				layout="total, sizes, prev, pager, next, jumper"
			>
			</el-pagination>
		</div>
		<imageEdit ref="edit" @success="typeImageList" />
	</div>
</template>

<script>
	import imageEdit from "./components/imageEdit.vue";
	export default{
		data(){
			return {
				loading: false,
				formSearch : {
					startPage : 1,
					pageSize : 10,
					typeCode : 2,
				},
				typeList:[],
				list:[],
				total : 0,
			}
		},
		created(){
			this.typeImageList();
		},
		mounted(){
			this.init_getTypeList();
		},
		onLoad(o){
			
		},
		methods: {
			on_exceed( files, fileList ){
				this.$message({
					type : 'error',
					message : "图片数量已经达到上传限制,请先删除"
				});
			},
			addImage( type , data ){
				this.$refs.edit.type = type;
				this.$refs.edit.open( data ? data : "" );
			},
			del_img( index ){
				
			},
			typeImageList(){
				this.loading = true;
				this.$api.deviceModelGetList( this.formSearch ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.formSearch.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.formSearch.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				})
			},
			init_getTypeList(){
				this.$api.device_getTypeList().then(res => {
					if (res.code == 200) {
						this.typeList = res.data
					}else{
						this.$message({
							type: 'error',
							message: "获取设备类型失败"
						});
					}
				});
			},
			device_list(){
				this.loading = true;
				this.$api.deviceModelGetList( this.formSearch ).then( res => {
					if( res.code == 200 ){
						this.list = res.data.list;
						this.formSearch.startPage = res.data.page;
						this.total = res.data.totalNum;
						this.loading = false;
					}else{
						this.list = [];
						this.formSearch.startPage = 1;
						this.total = 0;
						this.loading = false;
					}
				})
			},
			changePage( e ){
				this.formSearch.startPage = e;
				this.device_list();
			},
			sizeChange( e ){
				this.formSearch.pageSize = e;
				this.device_list();
			},
			delTypeImage( id ){
				this.$api.deviceModelDelete( { id } ).then( res => {
					this.$message({
						type: res.code == 200 ? 'success' : 'error',
						message: res.message
					});
					if( res.code == 200 ){
						if( this.list.length == 1 && this.form.startPage > 1 ){
							this.form.startPage = this.form.startPage - 1;
						}
						this.device_list();
					}
				});
				
			}
		},
		components:{
			imageEdit
		}
	}
</script>
<style lang="scss">
	.box {
		height: 25px;
	}

	.el-dialog__header {
		background: linear-gradient(135deg, #6b6be4, #15d8f1);
	}

	.el-dialog__title {
		color: #fff
	}

	.el-dialog__header {
		.el-dialog__headerbtn {
			.el-dialog__close {
				color: #fff;
			}
		}
	}
	$upload_size : 100px;
	.avatar-uploader .el-upload {
	  width: $upload_size;
	  height: $upload_size;
	  line-height: $upload_size;
	}
	.avatar-uploader .el-upload-list--picture-card .el-upload-list__item{
	  width: $upload_size;
	  height: $upload_size;
	  line-height: $upload_size;
	}
	.show_img_list{
	  display: flex;
	  flex-direction: row;
	  margin-top: 10px;
	  > div{
	    position: relative;
	    margin-right: 10px;
	    img{
	      width: 100px;
	      height: 100px;
	    }
	    .close{
	      position: absolute;
	      right: 5px;
	      top: 5px;
	      background-color: rgba( 255 , 255 , 255 , .5 );
	      color: #FFFFFF;
	      padding: 0px;
	      border-radius: 50%;
	      width: 15px;
	      height: 15px;
	      display: flex;
	      align-items: center;
	      cursor: pointer;
	      justify-content: center;
	      font-size: 12px;
	      &:hover{
	        transition: .5s all;
	        background-color: red;
	      }
	    }
	  }
	}
</style>